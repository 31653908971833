import { notification } from "antd";

export const DOMAIN = window.location.href.includes("localhost")
  ? "https://satoo.kz"
  : window.location.origin;

export enum CreateStepType {
  Form,
  Workflow,
}

export const getAccessToken = () => {
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo") || "{}")
    : null;
  return userInfo?.access ?? "";
};

export type NotificationType = "success" | "info" | "warning" | "error";

export const openNotificationWithIcon = (type: NotificationType, successDescription?: string) => {
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: successDescription,
    });
  } else {
    notification[type]({
      message: "Error",
      description: "Something went wrong.",
    });
  }
};
